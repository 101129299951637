'use client'

import * as React from 'react'
import { usePathname } from 'next/navigation'
import { locales } from 'utils/locales'

export interface BannerContextModel {
  bannerActive: boolean
}

export const BannerContext = React.createContext<BannerContextModel>({
  bannerActive: false,
})

export const BannerProvider = ({ children }: React.PropsWithChildren) => {
  const pathname = usePathname()
  const isMainPage = new RegExp(`^/(${locales.join('|')})?$`).test(pathname)

  // Scroll is minimized everywhere except the main page
  const value = !isMainPage

  return <BannerContext.Provider value={{ bannerActive: value }}>{children}</BannerContext.Provider>
}
